<h2 mat-dialog-title>Section #number</h2>
<mat-dialog-content class="mat-typography">
  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>

  <h3>Multiple content</h3>
  <p>
    Some very long text Some very long text Some very long text Some very long
    text Some very long text Some very long text Some very long text Some very
    long text Some very long text Some very long text Some very long text Some
    very long text Some very long text Some very long text Some very long text
    Some very long text Some very long text Some very long text Some very long
    text Some very long text.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>Cancel</button> -->
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
