import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SectionContentDialogComponent } from '../components/section-content-dialog/section-content-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'profile-dashboard';
  host = window.location.hostname;

  constructor(public dialog: MatDialog) {}

  openDialog(sectionId: string): void {
    const dialogRef = this.dialog.open(SectionContentDialogComponent);

    console.log('sectionId: ' + sectionId);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
