<div class="container-fluid">
  <div class="row mb-4"></div>

  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-lg-3 col-xl-3">
      <div class="card-box text-center">
        <img
          src="../assets/Van-Le_image_profile-v5.jpg"
          class="rounded-circle avatar-xl img-thumbnail"
          alt="profile-image"
        />

        <h4 class="mb-0">Van Le</h4>
        <p class="text-muted">Solutions Architect/Technical Lead</p>

        <div class="text-left mt-3">
          <h4 class="font-13 text-uppercase">About Me :</h4>
          <p class="text-muted font-13 mb-3">
            Hi, I'm Van Le, has been working in Software Development for more
            than 14 years - I have ever worked in various positions like: Senior
            Java Developer, Backend Team Lead, Frontend Team Lead, and Solutions
            Architect.
          </p>
          <p class="text-muted mb-2 font-13">
            <strong>Full Name :</strong>
            <span class="ml-2">Nguyen Trong Van Le</span>
          </p>
          <p class="text-muted mb-2 font-13">
            <strong>Mobile :</strong><span class="ml-2">TBD</span>
          </p>
          <p class="text-muted mb-2 font-13">
            <strong>Email :</strong>
            <span class="ml-2">lntvan629@gmail.com</span>
          </p>
          <p class="text-muted mb-2 font-13">
            <strong>LinkedIn :</strong>
            <span class="ml-2"
              ><a
                href="https://www.linkedin.com/in/van-le-29038354/"
                style="cursor: pointer"
                target="_blank"
                >van-le-29038354</a
              ></span
            >
          </p>
          <p class="text-muted mb-1 font-13">
            <strong>Location :</strong> <span class="ml-2">Atlanta Metropolitan Area, US</span>
          </p>
        </div>
      </div>
      <!-- end card-box -->

      <div class="card-box">
        <h4 class="header-title">Skills</h4>
        <p class="mb-3">
          Everyone realizes why a new common language would be desirable
        </p>

        <div class="pt-1">
          <h6 class="text-uppercase mt-0">
            Java technology <span class="float-right">90%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 90%"
            >
              <span class="sr-only">90% Complete</span>
            </div>
          </div>
        </div>

        <div class="pt-1">
          <h6 class="text-uppercase mt-0">
            Spring Framework <span class="float-right">85%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="85"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 85%"
            >
              <span class="sr-only">85% Complete</span>
            </div>
          </div>
        </div>

        <div class="pt-1">
          <h6 class="text-uppercase mt-0">
            Microservices <span class="float-right">80%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 80%"
            >
              <span class="sr-only">80% Complete</span>
            </div>
          </div>
        </div>

        <div class="mt-2 pt-1">
          <h6 class="text-uppercase">
            Angular <span class="float-right">70%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 70%"
            >
              <span class="sr-only">70% Complete</span>
            </div>
          </div>
        </div>

        <div class="mt-2 pt-1">
          <h6 class="text-uppercase">
            React <span class="float-right">72%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="72"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 72%"
            >
              <span class="sr-only">72% Complete</span>
            </div>
          </div>
        </div>

        <div class="mt-2 pt-1">
          <h6 class="text-uppercase">
            Docker <span class="float-right">70%</span>
          </h6>
          <div class="progress progress-sm m-0">
            <div
              class="progress-bar bg-purple"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width: 70%"
            >
              <span class="sr-only">70% Complete</span>
            </div>
          </div>
        </div>
      </div>
      <!-- end card-box-->
    </div>
    <!-- end col-->

    <div class="col-lg-7 col-xl-7">
      <div class="card-box">
        <ul class="nav nav-pills navtab-bg">
          <li class="nav-item">
            <a
              href="#about-me"
              data-toggle="tab"
              aria-expanded="true"
              class="nav-link ml-0 active"
            >
              <i class="mdi mdi-face-profile mr-1"></i>About Me
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane show active" id="about-me">
            <h5 class="mb-3 mt-4 text-uppercase">
              <i class="mdi mdi-cards-variant mr-1"></i>Demo technical stacks
            </h5>
            <div class="table-responsive">
              <table class="table table-border mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th>Course</th>
                    <th>Description</th>
                    <th>Demo Link</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Backend - Microservices</td>
                    <td>
                      <a
                        href="https://www.udemy.com/course/microservices-with-spring-boot-and-spring-cloud/"
                        target="_blank"
                        >Microservices with Spring...</a
                      >
                    </td>
                    <td>Spring Microservices && Docker</td>
                    <td>
                      <a
                        href="http://{{ host }}:3800"
                        style="cursor: pointer"
                        target="_blank"
                        >{{ host }}:3800</a
                      >
                    </td>
                    <td><span class="badge badge-success">DONE</span></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Frontend</td>
                    <td colspan="3">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <a
                              href="https://www.udemy.com/course/react-the-complete-guide-incl-redux/"
                              target="_blank"
                              >React - The Complete Guide</a
                            ></mat-panel-title
                          >
                          <mat-panel-description>
                            Reactjs, Hooks, Redux, React Routing, Animations,
                            Next.js
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <table class="table table-border mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>Section</th>
                              <th>Content</th>
                              <th>Link</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1-7</td>
                              <td>Getting Started - React Basics</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3001"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3001</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>1-7</td>
                              <td>Getting Started - React Basics</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3002"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3002</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>
                                Time to Practice: A Complete Practice Project
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3003"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3003</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>
                                Advanced: Handling Side Effects, Using Reducers
                                & Using the Context API
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3004"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3004</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td>
                                Practice Project: Building a Food Order App
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3005"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3005</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>14</td>
                              <td>
                                Sending Http Requests (e.g. Connecting to a
                                Database)
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3006"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3006</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>18</td>
                              <td>
                                Diving into Redux (An Alternative To The Context
                                API)
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3007"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3007</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>19</td>
                              <td>Advanced Redux</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3008"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3008</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>20</td>
                              <td>
                                Building a Multi-Page SPA with React Router
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3009"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3009</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>20p</td>
                              <td>
                                Building a Multi-Page SPA with React Router
                                (Cont.)
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3010"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3010</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>21</td>
                              <td>Deploying React Apps</td>
                              <td>
                                <a
                                  href="https://react-http-4c1cc.web.app"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >https://react-http-4c1cc.web.app</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>22</td>
                              <td>Adding Authentication To React Apps</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3011"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3011</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>23</td>
                              <td>
                                A (Pretty Deep Dive) Introduction to Next.js
                              </td>
                              <td>
                                <a
                                  href="https://react-complete-guide-updated-section23.vercel.app/"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >React on NextJS</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>24</td>
                              <td>Animating React Apps</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3012"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3012</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>25</td>
                              <td>Replacing Redux with React Hooks</td>
                              <td>
                                <a
                                  href="http://{{ host }}:3013"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3013</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>27</td>
                              <td>React + <b>TypeScript</b></td>
                              <td>
                                <a
                                  href="http://{{ host }}:3014"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3014</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>28</td>
                              <td>
                                Optional: React Hooks Introduction & Summary
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3015"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3015</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>29</td>
                              <td>
                                Optional: React Summary & Core Feature
                                Walkthrough
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3016"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3016</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>29p</td>
                              <td>
                                Optional: React Summary & Core Feature
                                Walkthrough (Cont.)
                              </td>
                              <td>
                                <a
                                  href="http://{{ host }}:3017"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:3017</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-expansion-panel>
                    </td>
                    <td><span class="badge badge-success">DONE</span></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Frontend</td>
                    <td>AngularJS</td>
                    <td>AngularJS 1.5.0</td>
                    <td>
                      <a
                        href="http://{{ host }}:3000"
                        style="cursor: pointer"
                        target="_blank"
                        >{{ host }}:3000</a
                      >
                    </td>
                    <td><span class="badge badge-success">DONE</span></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Frontend</td>
                    <td colspan="3">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <a
                              href="https://www.udemy.com/course/the-complete-guide-to-angular-2/"
                              target="_blank"
                              >Angular - The Complete Guide</a
                            ></mat-panel-title
                          >
                          <mat-panel-description>
                            Master Angular 13 (formerly "Angular 2")
                          </mat-panel-description>
                        </mat-expansion-panel-header>

                        <table class="table table-border mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>Section</th>
                              <th>Content</th>
                              <th>Link</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1-10</td>
                              <td>Getting Started - Angular Basics</td>
                              <td>
                                <a
                                  href="http://{{ host }}:4200"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:4200</a
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>1-10</td>
                              <td>Getting Started - Angular Basics</td>
                              <td>
                                <a
                                  href="http://{{ host }}:4201"
                                  style="cursor: pointer"
                                  target="_blank"
                                  >{{ host }}:4201</a
                                >
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </mat-expansion-panel>
                    </td>
                    <td><span class="badge badge-info">WIP</span></td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Frontend</td>
                    <td>
                      <a
                        href="https://www.udemy.com/course/understanding-typescript/"
                        target="_blank"
                        >Understanding TypeScript</a
                      >
                    </td>
                    <td>React & TypeScript</td>
                    <td>
                      <a
                        href="http://{{ host }}:3018"
                        style="cursor: pointer"
                        target="_blank"
                        >{{ host }}:3018</a
                      >
                    </td>
                    <td><span class="badge badge-success">DONE</span></td>
                  </tr>

                  <!-- <tr>
                    <td><span class="badge badge-info">Pending</span></td>
                     <td><span class="badge badge-pink">Done</span></td>
                    <td><span class="badge badge-purple">Work in Progress</span></td>
                    <td><span class="badge badge-warning">Coming soon</span></td>
                  </tr> -->
                </tbody>
              </table>
            </div>

            <div class="row mb-4"></div>

            <h5 class="mb-4 text-uppercase">
              <i class="mdi mdi-briefcase mr-1"></i> Experience
            </h5>

            <ul class="list-unstyled timeline-sm">
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2019 - Now</span>
                <h5 class="mt-0 mb-1">Technical Solutions Lead</h5>
                <p>FPT USA Corporation</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  TBD
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Infrastructure: Amazon Web Services (AWS) Cloud platform &
                  On-premise - Platform/Framework: Java Spring Microservices,
                  Spring boot, Ruby on Rails - Frontend Framework: Angular 10+,
                  React - Database: MySQL - Testing Tool: Junit, Karma, Cypress
                  - Code Review and Monitoring Tools: SonarQube - Source
                  Control: Github - CICD: Jenkins, Shell Scripting.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2019 - 2020</span>
                <h5 class="mt-0 mb-1">Solutions Architect - Team Lead</h5>
                <p>FPT USA Corporation</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  Sysmex Validation Solution (SVS) s a web-based automated
                  testing tool designed to test defined rules which are
                  activated within Sysmex middleware products (WAM 5.0.2,
                  Caresphere Workflow Solution (cloud), Caresphere Workflow
                  Solution (on-prem)). This solution also produces test evidence
                  for customer reporting purposes to be leveraged to meet the
                  customers' College of American Pathologists (CAP)
                  requirements. SVS will be used with following emulators: XN,
                  XN-L, X-Series, CellaVision, UDM.
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Infrastructure: Amazon Web Services (AWS) Cloud platform &
                  On-premise - Platform/Framework: Java, Winium and Selenium -
                  Database: PostgreSQL - Testing Tool: Junit, Karma - Code
                  Review and Monitoring Tools: SonarQube - Source Control:
                  Bitbucket - CICD: Jenkins, Shell Scripting.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2017 - 2019</span>
                <h5 class="mt-0 mb-1">Front-End Team Leader</h5>
                <p>FPT Software Hochiminh Co. Ltd.</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  Bank National Canada (BNC) web application is a digital
                  banking solution with 2 main features: manage your finance and
                  plan your goal in future. Typically, customer of BNC can
                  access many features on e-banking such as: Financial Health,
                  Goal Dashboard, Client Profile, Balance Sheet, Cash Flow and
                  Contact Advisor. Particularly, in Goal Dashboard, user can
                  monitor each goal and (s)he can add more goal or adjust every
                  goal.
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Frontend Framework: AngularJS 1.5.10, NodeJS, Bootstrap.js,
                  Karma, Jasmine, Babel, Webpack - Javascript library/module:
                  Fusion chart, iCheck, ngStorage, Object-mapper - Tool: Form
                  Builder, Maven - Server: Virgo.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2016 - 2017</span>
                <h5 class="mt-0 mb-1">
                  Senior Java Developer – Android Developer
                </h5>
                <p>FPT Software Hochiminh Co. Ltd.</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  CommBank e-Kiosk application is a digital solution to offer an
                  easier way to open a bank account: simple, easy, and immediate
                  digital financial experience that saves time and gives better
                  experience. It has been initiated with the considerations:
                  enabling a faster turnaround time and a much more convenient
                  way to open an account without requiring customer to go to the
                  bank; cheaper operational cost for the Bank with CommBank
                  e-Kiosk implementation rather than to open a new branch; a
                  digital integration between Bank’s digital channel physical
                  and mobile as well as with the core banking system to enable a
                  better customer experience.
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Framework: Spring Microservices, Spring RESTful webservice,
                  Java Design Patterns - Server: Tomcat 8 - Database management:
                  Hibernate - Database: Oracle Android: Dagger framework in
                  Android app.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2015 - 2016</span>
                <h5 class="mt-0 mb-1">
                  Senior Java Developer – Team Leader backend service
                </h5>
                <p>FPT Software Hochiminh Co. Ltd.</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  CapitaStar is a multi-mall, multi-store card-less rewards /
                  customer loyalty program by CapitaLand. Star$ is the loyalty
                  point which is redeemable for CapitaVouchers or other
                  promotion gifts from time to time. The CapitaStar member can
                  download the CapitaStar mobile app from Google Play or App
                  Store.
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Framework: Spring MVC, Spring RESTful webservice, Spring AOP,
                  Java Design Patterns - Server: Tomcat 8 - Database management:
                  Hibernate - Database: SQL Server 2014 Others: push
                  notification message to FCM, Log4JDBC, Jacoco (Java Code
                  Coverage).
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2013 - 2015</span>
                <h5 class="mt-0 mb-1">Senior Java Developer - PM</h5>
                <p>FPT Software Hochiminh Co. Ltd.</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  The Event Scheduler module of the DIRECTV Traffic and
                  Scheduling System (DTSS) is used to schedule programming on
                  DTSS “schedule channels”. Schedule channels are the content
                  channels whose schedules are used to derive automation events
                  (which define what is broadcast and how) and the conditional
                  access events that relate to them. While other DTSS tools may
                  exist that make certain types of scheduling less labor
                  intensive and less error prone (the Daily Sports and Special
                  Events, or DSSE, interface would be an example), the Event
                  Scheduler has some helpful tools of its own built in, and
                  represents a “lowest common denominator” of scheduling
                  interfaces which can be used to schedule any type of
                  programming broadcast by DIRECTV.
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Framework: Enterprise Java Bean (EJB) - Client side: GWT -
                  Server: Weblogic, JBoss - Database management: JPA - Database:
                  SyBase, Oracle.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2010 - 2013</span>
                <h5 class="mt-0 mb-1">Senior Java Developer - Scrum Master</h5>
                <p>Axon Active Vietnam Ltd.</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  Analysis, process and give an advice with the best result
                  matching with criteria for customer about Campaign
                  Advertisement (Out Door Advertisement).
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Framework: Spring Framework, Spring Web Flow, Spring Batch job
                  - Client side: Jquery, Jquery UI, Google Map API - Server:
                  Apache Geronimo, Tomcat - Database management: iBatis -
                  Database: Postgre and PostGis - Reports: Jasper report,
                  iReport - Other: Geo server, log4jdbc, Spring
                  Internationalization.
                </p>
              </li>
              <li class="timeline-sm-item">
                <span class="timeline-sm-date">2007 - 2010</span>
                <h5 class="mt-0 mb-1">Java Developer</h5>
                <p>Terminal C VN</p>
                <p class="text-muted mt-2 mb-0">
                  <b>Project Description: </b>
                  Manage and dispatch Transport. It is a management project for
                  Helmic company, Danner’s incorporated in U.S
                </p>
                <p class="text-muted mt-2 mb-0">
                  <b>Technologies: </b>
                  Framework: Groovy & Grails, WebWork, Struts - Client side:
                  Javascript, Jquery, Jquery UI - Server: Tomcat - Database
                  management: Hibernate - Database: MySQL, SQL Server, Oracle -
                  Reports: Jasper report, iReport.
                </p>
              </li>
            </ul>
          </div>
          <!-- end timeline content-->
        </div>
        <!-- end tab-content -->
      </div>
      <!-- end card-box-->
    </div>
    <div class="col-sm-1"></div>
    <!-- end col -->
  </div>
</div>
