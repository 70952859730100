import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

import { SectionContentDialogComponent } from '../section-content-dialog/section-content-dialog.component';

@NgModule({
  declarations: [SectionContentDialogComponent],
  entryComponents: [SectionContentDialogComponent],
  imports: [FormsModule, MatDialogModule],
})
export class SectionContentDialogModule {}
