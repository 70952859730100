import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatExpansionModule } from '@angular/material/expansion';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SectionContentDialogModule } from '../components/section-content-dialog/section-content-dialog.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, MatExpansionModule, SectionContentDialogModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
