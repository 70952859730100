import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'section-content-dialog',
  templateUrl: 'section-content-dialog.component.html',
})
export class SectionContentDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SectionContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
